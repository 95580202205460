<!--主推套餐设置-->
<template>
  <div class="package">
    <div class="item">
      <div class="top">
        <span>是否开启主推套餐</span>
        <el-switch v-model="tableParams.configEnable" active-color="#ef9807">
        </el-switch>
      </div>
      <div class="box">
        <span class="red">*</span>
        <span>选择卡券</span>
        <el-select
          class="select"
          v-model="tableParams.oneCardId"
          placeholder="选择卡券"
        >
          <el-option
            v-for="item in cardList"
            :key="item.cardId"
            :label="item.cardName"
            :value="item.cardId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="box">
        <span class="red">*</span>
        <span>选择卡券</span>
        <el-select
          class="select"
          v-model="tableParams.twoCardId"
          placeholder="选择卡券"
        >
          <el-option
            v-for="item in cardList"
            :key="item.cardId"
            :label="item.cardName"
            :value="item.cardId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="box">
        <span class="red">*</span>
        <span>选择卡券</span>
        <el-select
          class="select"
          v-model="tableParams.threeCardId"
          placeholder="选择卡券"
        >
          <el-option
            v-for="item in cardList"
            :key="item.cardId"
            :label="item.cardName"
            :value="item.cardId"
          >
          </el-option>
        </el-select>
      </div>
      <el-button type="primary" @click="goSave()">保存</el-button>
    </div>
    <div class="item i2">
      <p>小程序展示样式</p>
      <img class="img" src="~@/assets/images/mini/package.png" alt="" />
    </div>
  </div>
</template>
<script>
import {
  getAllCardList,
  getConfigByShopId,
  goUpdateConfig,
} from "@/api/card/channel.js";
export default {
  data() {
    return {
      tableParams: {
        merchantId: "",
        shopId: "",
        cardStatus: 1,
        configEnable: false,
        oneCardId: "",
        twoCardId: "",
        threeCardId: "",
      },
      cardList: [],
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
    this.getAllCardList();
    this.getConfigByShopId();
  },
  methods: {
    // 查询套餐详情
    getConfigByShopId() {
      getConfigByShopId(this.tableParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableParams = res.data;
        }
      });
    },
    // 更新套餐
    goUpdateConfig() {
      goUpdateConfig(this.tableParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getConfigByShopId();
        }
      });
    },
    // 查询卡券列表
    getAllCardList() {
      let params = {
        merchantId: this.tableParams.merchantId,
        shopId: this.tableParams.shopId,
      };
      getAllCardList(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.cardList = res.data;
        }
      });
    },
    // 保存
    goSave() {
      if (
        this.tableParams.configEnable &&
        (this.tableParams.oneCardId == null ||
          this.tableParams.twoCardId == null ||
          this.tableParams.threeCardId == null)
      ) {
        this.$message({
          message: "请选择卡券后再进行保存",
          type: "error",
        });
      } else {
        this.goUpdateConfig();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.package {
  display: flex;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .top {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .el-switch {
      margin-left: 20px;
    }
  }
  .box {
    width: 500px;
    height: 100px;
    border: 1px dotted #999;
    border-radius: 4px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .red {
      color: red;
    }
    .select {
      width: 300px;
      margin-left: 20px;
    }
  }

  p {
    margin: 0.1rem 0;
  }
}
.i2{
    align-items: center;
}
</style>