// 渠道兑换码管理
import request from "@/utils/request";
import Export from "@/utils/export";

// 生成渠道码
export function goCreateChannelCode(data) {
  return request("post", "/apm/channel/code/create", data);
}

// 渠道码列表
export function getChannelCodeList(data) {
  return request("post", "/apm/channel/code/page", data);
}

// 启用停用渠道码
export function goEnableChannelCode(data) {
  return request("post", "/apm/channel/code/enable", data);
}

// 删除渠道码
export function goRemoveChannelCode(data) {
  return request("post", "/apm/channel/code/removeById", data);
}

// 换绑渠道码
export function goUpdateBindChannelCode(data) {
  return request("post", "/apm/channel/code/update/bind", data);
}

// 根据门店id查询卡券列表-不分页
export function getAllCardList(data) {
  return request("post", "/apm/cardCoupon/listByShopId", data);
}

// 查看小程序码
export function getQrCode(data) {
  return request("post", "/apm/channel/code/getQrCode", data);
}

// 列表导出
export function goExportQrCodeList(data,name) {
    return Export("post", "/apm/channel/code/export", data,name);
  }
  // 列表导出
export function getExchangeRecord(data,name) {
    return request("post", "/apm/channel/code/exchange/record", data,name);
  }

    // 门店主推套餐详情
export function getConfigByShopId(data) {
  return request("post", "/apm/card/package/config/findByShopId", data);
}

    // 门店主推套餐详情
    export function goUpdateConfig(data) {
      return request("post", "/apm/card/package/config/update", data);
    }